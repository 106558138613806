import { IUserPreviewResource } from '@/types/User';
import { IClientPreviewResource } from '@/types/Client';
import { IPlannedProjectTaskResource, IProjectTaskResource, IProjectUpdateTaskRequest } from '@/types/ProjectTask';
import { BillingFrequency, ProjectPriceType, RepetitionFrequencyType } from '@/types/Common';
import { IServiceCheckpointResource, IServicePreviewResource } from '@/types/Service';
import { IFinancialYearResource } from '@/types/FinancialYear';
import { CheckpointEventStateType, CheckpointFrequency, ICheckpointListResource } from '@/types/Checkpoint';

export enum ProjectStatusType {
  Draft = 'draft',
  Active = 'active',
  Done = 'done',
  Cancelled = 'cancelled',
  Deleted = 'deleted',
}

export type ProjectStepType = 'service' | 'price' | 'team' | 'planning' | 'activate' | 'active';

export enum ProjectStep {
  Service = 'service',
  Price = 'price',
  Team = 'team',
  Planning = 'planning',
  Activate = 'activate',
  Active = 'active',
}

export interface IProjectClientTabResource {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  deadline_date: string;
  financial_year: {
    uuid: string;
    start_date: string;
    end_date: string;
  };
  status: ProjectStatusType;
  total_reported_time: number;
  total_estimated_time: number;
  total_forecasted_time: number;
  total_minutes: number;
  external_total_price: number;
  marginality: number;
  marginality_percent: number;
  notes: null | string;
  status_changed_at: null | string;
  has_unfinished_tasks: boolean;
}

export enum ProjectBillingPlanType {
  BaseFee = 'base_fee',
  SeparateFee = 'separate_fee',
  HourlyFee = 'hourly_fee',
}

export interface IProjectBillingPlanResource {
  id: string;
  type: ProjectBillingPlanType;
  price: string | number;
  month: number;
  year: number;
  is_invoiced: boolean;
  is_forecasted: boolean;
}

export interface IProjectPlannedBillingPlanResource {
  id: string;
  type: ProjectBillingPlanType;
  price: string | number;
  month: number;
  year: number;
  is_invoiced: boolean;
  is_forecasted: boolean;
}

export interface IProjectBillingPlanRequestBody {
  id?: null | number;
  parent_project_billing_plan_id?: null | number;
  price: string | number;
  month: number;
  year: number;
}

export interface IProjectBillingPlan {
  id?: number;
  uid: string;
  price: string | number;
  month: number;
  year: number;
  is_invoiced: boolean;
}

export interface IProjectPlannedBillingPlan extends IProjectBillingPlan {
  parent_project_billing_plan_id?: number | null;
}

export interface IBillingCell {
  uid: string;
  month: number;
  year: number;
}

export interface IProjectTaskEventRequestBody {
  id?: number;
  scheduled_time: null | number;
  user_uuid: null | string;
  week: null | number;
  parent_event_id?: number;
  is_planned?: boolean;
}

export interface IProjectCheckpointEventRequest {
  state?: CheckpointEventStateType;
  comment?: null | string;
}

export interface IProjectCheckpointEventResource {
  id: number;
  name: string;
  year_month: number;
  state: CheckpointEventStateType;
  state_changed_at: string;
  comment: null | string;
}

export interface IProjectCheckpointResource {
  id: number;
  name: string;
  frequency: CheckpointFrequency;
  service_checkpoint: IServiceCheckpointResource;
  events: IProjectCheckpointEventResource[];
}

export type IProjectCheckpointListResource = Pick<IProjectCheckpointResource, 'id' | 'name'> & {
  responsible: IUserPreviewResource;
  checkpoints: ICheckpointListResource[];
} & Pick<IProjectResource, 'start_date' | 'end_date' | 'financial_year' | 'service'>;

export interface IProjectResource {
  id: number;
  name: string;
  step: ProjectStepType;
  status: ProjectStatusType;
  start_date: string;
  end_date: string;
  deadline_date: string;
  price_type: null | ProjectPriceType;
  only_invoice_part_of_year: null | boolean;
  is_sub_label_enabled: boolean;
  billing_frequency: null | BillingFrequency;
  annual_price: null | number;
  estimating_price: null | number;
  discount: null | number;
  notes: null | string;
  number_of_months_for_invoices_per_part_of_year: null | number;
  has_unfinished_tasks: boolean;
  service: IServicePreviewResource;
  financial_year: IFinancialYearResource;
  user: IUserPreviewResource;
  billing_plans: IProjectBillingPlanResource[];
  planned_billing_plans: IProjectPlannedBillingPlanResource[];
  tasks: IProjectTaskResource[];
  planned_tasks: IPlannedProjectTaskResource[];
  checkpoints: IProjectCheckpointResource[];
  client: IClientPreviewResource;
}

export type IProjectTaskListResource = Pick<IProjectResource, 'id' | 'name'>;

export interface IProjectPreviewResource
  extends Pick<IProjectResource, 'id' | 'name' | 'step' | 'status' | 'has_unfinished_tasks'> {
  client: IClientPreviewResource;
}

export interface IProjectWithTeamsResource
  extends Pick<
    IProjectResource,
    | 'id'
    | 'name'
    | 'start_date'
    | 'end_date'
    | 'deadline_date'
    | 'price_type'
    | 'annual_price'
    | 'estimating_price'
    | 'status'
  > {
  total_repeat_time: number;
  total_reported_time: number;
  total_estimated_time: number;
  total_forecasted_time: number;
  budgeted: number;
  done_at: boolean;
  marginality: number;
  teams: IUserPreviewResource[];
  has_unfinished_tasks: boolean;
}

export interface IProjectRequestServiceStepBody {
  name: null | string;
  service_uuid: null | string;
  financial_year_uuid: null | string;
  user_uuid: null | string;
  start_date: null | string;
  end_date: null | string;
  deadline_date: null | string;
  step: ProjectStep.Service;
}

export interface IProjectRequestPriceStepBody {
  price_type: null | ProjectPriceType;
  billing_frequency: null | BillingFrequency;
  annual_price: null | number;
  billing_plans: IProjectBillingPlanRequestBody[];
  estimating_price: null | number;
  discount: null | number;
  step: ProjectStep.Price;
  only_invoice_part_of_year: boolean;
  number_of_months_for_invoices_per_part_of_year: number;
}

export type ProjectTeamMemberRole = 'team_member' | 'responsible';

export interface IProjectRequestTeamMemberBody {
  user_uuid: string;
  role: ProjectTeamMemberRole;
}

export interface IProjectRequestTeamStepBody {
  step: ProjectStep.Team;
  team_members: IProjectRequestTeamMemberBody[];
}

export interface IProjectTeamMemberListResource {
  uuid: string;
  name: string;
  role: ProjectTeamMemberRole;
  is_active: boolean;
}

export interface IProjectTeamMemberRequestBody {
  user_uuid: string;
}

export interface IProjectRequestActivateStepBody {
  step: ProjectStep.Activate;
}

export interface IProjectRequestNotes {
  notes: string | null;
}

export interface IManageProjectTasksRequest {
  change_project_step: boolean;
  is_sub_label_enabled: boolean;
  tasks: IProjectUpdateTaskRequest[];
}

export interface IGetProjectTasksEstimatesRequestBody {
  tasks: number[];
  planned_tasks: number[];
}

export interface IProjectTaskEstimates {
  plannedTasks: { id: number; production_cost: number }[];
  tasks: { id: number; production_cost: number }[];
}

export interface IProjectTaskGenerateEventsRequestBody {
  task_id: null | undefined | number;
  default_frequency: null | RepetitionFrequencyType;
  week: null | number;
  default_time_budget: null | number;
  user_uuid: null | string;
}

export interface IProjectTaskGeneratedEvents {
  id?: number;
  week: number;
  scheduled_time: number;
  user_uuid: string;
  is_done: boolean;
  is_invoiced: boolean;
  has_tracked_time: boolean;
  deadline: null | string;
}

export interface IProjectEstimates {
  current_project: {
    total_minutes: number;
    internal_total_price: number;
    external_total_price: number;
    marginality: number;
    marginality_percent: number;
  };
  next_project: {
    total_minutes: number;
    internal_total_price: number;
    external_total_price: number;
    marginality: number;
    marginality_percent: number;
  };
}

export interface IProjectMargin {
  project_id: number;
  total_minutes: number;
  internal_total_price: number;
  external_total_price: number;
  marginality: number;
  marginality_percent: number;
}

export type ITimeEntryProjectResource = Pick<
  IProjectResource,
  'id' | 'name' | 'estimating_price' | 'annual_price' | 'price_type' | 'discount' | 'is_sub_label_enabled'
>;

export interface ICancelOrRolloverProjectOptions {
  start_new_project: boolean;
  increase_prices?: boolean;
  percentage_price_increase?: number;
  name: null | string;
  financial_year_uuid: null | string;
  start_date: null | string;
  end_date: null | string;
  deadline_date: null | string;
}
