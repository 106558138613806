<script setup lang="ts">
/* eslint-disable */
import { ref, onMounted, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { Dropdown } from 'floating-vue';
import { useModal } from 'vue-final-modal';

//
import api from '@/services/api';
import {
  AppAlert,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  AppButton,
  FontIcon,
  FormInput,
  FormLabel,
  AppPagination,
  AppLoader,
  ConfirmDialog,
  AppCollapse,
  ColumnSettings,
  HelpInformation,
  ConfirmModal,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useColumnSettings from '@/composables/useColumnSettings';
import { IClientPreviewResource } from '@/types/Client';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { useTitle } from '@vueuse/core';

const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();

const contacts = ref<number[]>([1]);

// Pagination
const page = useRouteQuery<number>('page', 1, { transform: Number });
const perPage = ref(20);
const total = ref(0);

// Columns
const { columns, isColumnEnable, enabledColumns } = useColumnSettings('contacts_columns', [
  'company_links',
  'service_company_link',
  'service_company_type',
  'service_company_title',
  'comment',
]);

// Filters
const filterLoader = useLoader();
const filterSearch = useRouteQuery<string>('search', '');

async function resetFilters() {
  filterSearch.value = '';
  await onFilter();
}

function onDelete(contact: any) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('contact.confirm.destroy.title'),
      message: t('contact.confirm.destroy.text', {
        name: contact.name,
      }),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        setLoading(true);
        try {
          // await api.contacts.destroy(contact.uuid);
          await getContacts();
          if (contacts.value.length === 0 && page.value > 1) {
            page.value -= 1;
          }
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

async function getContacts() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', page.value.toString());
    searchParams.append('sort_by', 'name');
    searchParams.append('sort_dir', 'asc');
    // Set filters
    if (filterSearch.value) searchParams.append('search', filterSearch.value);

    // const response = await api.clients.list(searchParams);
    // contacts.value = response.data;
    // perPage.value = response.meta!.per_page;
    // total.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  }
}

async function onFilter() {
  filterLoader.start();
  page.value = 1;
  await getContacts();
  filterLoader.finish();
}

onMounted(async () => {
  loader.start();
  await Promise.all([getContacts()]);
  loader.finish();
});

watch(page, async () => {
  loader.start();
  await getContacts();
  loader.finish();
});

const title = useTitle(computed(() => t('contact.index.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="contact.index.help" />
      </div>
      <AppButton class="ml-auto" color="secondary">
        {{ t('contact.index.export_contacts') }}
      </AppButton>
      <Dropdown placement="bottom-end" :distance="10">
        <AppButton class="ml-2" light circle>
          <FontIcon name="table-options" />
        </AppButton>
        <template #popper>
          <ColumnSettings
            v-model="enabledColumns"
            :columns="columns"
            :get-label="(columnName) => t(`contact.attributes.${columnName}`)"
          />
        </template>
      </Dropdown>
    </div>
    <AppCollapse class="my-3" :title="t('common.filters')">
      <form @submit.prevent="onFilter" class="my-3">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 align-items-end">
          <!-- Customer name or number -->
          <div class="form-group col">
            <FormLabel html-for="filter_search">
              {{ t('contact.filters.search') }}
            </FormLabel>
            <FormInput type="search" v-model.trim="filterSearch" id="filter_search" icon="search" />
          </div>
        </div>
        <div>
          <AppButton :disabled="filterLoader.isLoading.value">
            {{ t('common.apply_filters') }}
          </AppButton>
          <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="filterLoader.isLoading.value">
            {{ t('common.reset_filters') }}
          </AppButton>
        </div>
      </form>
    </AppCollapse>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="contacts.length === 0">
        {{ t('contact.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>{{ t('contact.attributes.name') }}</AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('company_links')">
                {{ t('contact.attributes.company_links') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('service_company_link')">
                {{ t('contact.attributes.service_company_link') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('service_company_type')">
                {{ t('contact.attributes.service_company_type') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('service_company_title')">
                {{ t('contact.attributes.service_company_title') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('comment')">
                {{ t('contact.attributes.comment') }}
              </AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr v-for="contact in contacts" :key="contact">
              <AppTableTd nowrap>
                <strong v-text="'Contact name'" />
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('name')"></AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('company_links')"></AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('service_company_link')"></AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('service_company_type')"></AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('service_company_title')"></AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('comment')"></AppTableTd>
              <AppTableTd nowrap class="text-right">
                <AppButton
                  v-tooltip.left="t('contact.tooltip.crm', { name: 'Name' })"
                  class="ml-2"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="target-arrow" />
                </AppButton>
                <AppButton
                  v-tooltip.left="t('contact.tooltip.destroy', { name: 'Name' })"
                  @click.stop="onDelete(contact)"
                  class="ml-2"
                  color="danger"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="trash" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination :per-page="perPage" :total="total" v-model="page" />
      </template>
    </template>
  </div>
</template>
