export default {
  index: {
    title: 'Customers',
    create: 'Create customer',
    help: '',
    filters: {
      name_or_number: 'Customer name or number',
      industries: 'Industry',
      group_tag: 'Tag',
      city: 'City',
      fiscal_year_end: 'Fiscal year end',
      service_organisation: 'Service org',
      service_organisation_contact: 'Service org contact',
      services: 'Services',
      project_name: 'Projects',
      team_member: 'Team member',
      office: 'Office',
      show_all: 'Show all',
      show_only_active: 'Show only active',
      show_connected_companies: 'Show connected companies',
    },
  },
  create: {
    title: 'Customer',
    help: '',
  },
  edit: {
    title: 'Edit customer: {name} ({org_number})',
    help: '',
  },
  service_company: {
    title: 'Service organisation & Third party contacts',
    add: {
      title: 'Link service organisation',
      service_company: 'Service organisation',
    },
    tooltip: {
      detach: 'Unlink {name} from the customer',
    },
    confirm: {
      detach: {
        title: 'Unlink service organisation',
        text: 'Are you sure you want to unlink <strong>{name}</strong> from the client?',
      },
    },
    contact: {
      title: 'Contacts',
      empty: 'No service organisation contacts',
      add: {
        title: 'Link contact',
        contact: 'Contact',
      },
      attributes: {
        account_in_portal: 'Access to portal',
        portal_status: 'Portal status',
      },
      tooltip: {
        attach: 'Link new contact',
        detach: 'Unlink {name} from the service organisation',
      },
      confirm: {
        detach: {
          title: 'Unlink contact',
          text: 'Are you sure you want to unlink <strong>{name}</strong> from <strong>{company}</strong> service organisation?',
        },
      },
    },
  },
  projects: {
    index: {
      title: 'Customer projects: {name} ({org_number})',
      create: 'Create project',
      back: "Customer's project",
    },
  },
  form: {
    general_info: 'General info',
    search_client: 'Search customer',
    client_type: 'Customer type',
    company: 'Organization',
    individual: 'Private person',
    client_company: 'Customer name or organisation number',
    client_individual: 'Customer name or social security number',
    registered_address: 'Registered address',
    invoice_address: 'Invoice address',
    invoice_settings: 'Invoice settings',
    info_and_settings: 'Info & settings',
    customer_info: 'Customer info',
    customer_and_project_settings: 'Customer & project settings',
    audit_service_info: 'Audit service info',
  },

  empty: 'No customer. Apply filter to find.',

  // Attributes
  attributes: {
    name: 'Name',
    industry: 'Industry',
    group_tag: 'Tags',
    service_companies: 'Service org',
    service_company_contacts: 'Service org contact',
    active_services: 'Services',
    active_projects: 'Projects',
    project_responsible: 'Project responsible',
    office: 'Office',
    org_number: 'OrgNr',
    vat: 'VAT number',
    company_category: 'Org type',
    main_phone: 'Main phone',
    responsible_user: 'Customer responsible',
    last_financial_year: 'Fiscal year',
    start_financial_year_date: 'Fiscal year start',
    end_financial_year_date: 'Fiscal year end',
    first_address: 'Address 1',
    second_address: 'Address 2',
    zip: 'Zip',
    city: 'City/State',
    country: 'Country',
    invoice_email: 'Invoice email address',
    invoice_default_contact_uuid: 'Customer reference',
    invoice_grouping: 'Invoice grouping',
    invoice_default_language: 'Invoice language',
    invoice_default_info: 'Other invoice information',
    invoice_distribution: 'Invoice distribution type',
    r: 'R',
    e: 'E',
    b: 'B',
    p: 'P',
    reported: 'Reported',
    estimated: 'Estimated',
    budgeted: 'Budgeted',
    planned: 'Planned',
    marginal: 'Marginal',
    other_info: 'Other information',
    specific_procedures: 'Specific procedures',
    service_or_project: 'Service / Project',
    tags: 'Tags',
    large_company: 'Large company?',
    has_formal_board_meetings: 'Client has formal board meetings',
    prepares_financial_budgets: 'Clients prepares financial budgets',
    registered_as_employer: 'Registered as employer',
    registered_for_vat: 'Registered for VAT',
    vat_reporting_dates: 'VAT reporting dates',
    registered_for_f_tax: 'Registered for F-tax',
    part_of_company_group: 'Part of company group',
    holding_company: 'Holding company',
    consolidates_group_financial_statement: 'Consolidates group financial statement',
    dormant_limited_operations: 'Dormant/Limited operations',
    customer_status: 'Customer status',
    use_we_connect: 'Use WeConnect client portal',
    insourced_services: 'Insourced services (we work at clients premises)',
    first_year_audit: 'First year audit',
    audited_by_another_firm_previous_year: 'Audited by another firm previous year',
  },

  invoice_grouping: {
    without_grouping: 'By project',
    by_project_responsible: 'By project responsible',
    by_client_responsible: 'By client responsible',
  },

  // Tooltips
  tooltip: {
    edit: 'Edit "{name}" client',
    destroy: 'Delete "{name}" client',
    projects: 'Show "{name}" client projects',
  },

  contact: {
    title: 'Contacts',
    name: 'Name',
    short_name: 'Short name',
    personal_number: 'Personal number',
    responsible_user: 'Customer responsible',
    email: 'Email',
    phone: 'Phone',
    roles: 'Roles',
    nationality: 'Nationality',
    comment: 'Comment',
    subscription_list: 'Subscription list',
    owner: 'Owner',
    access_to_portal: 'Access to portal',
    portal_status: 'Portal status',
    create: 'Create',
    sync: 'Sync',
    empty: 'No contacts',
    modal: {
      title: 'Create contact',
      edit_title: 'Edit contact: {name}',
      create_role_name: 'Create new role: {name}',
    },
    tooltip: {
      edit: 'Edit "{name}" contact',
      destroy: 'Delete "{name}" contact',
      activate_we_connect: 'Activate the WeConnect customer portal for this customer to invite contact person',
    },
  },

  overview: {
    invoice_credit_info: 'Invoice and credit info',
    download_credit_report: 'Download credit report',
    due: 'Due invoice',
    credit_rating: 'Credit rating',
    skulsaldo: 'Skulsaldo hos KFM',
    payment_remarks: 'Payment Remarks',
    registered_information: 'Registered information',
    general_information: 'General information',
    status: 'Status',
    incorporation_date: 'Incorporation date',
    sni: 'SNI',
    business_description: 'Business description',
    share_capital: 'Share capital',
    tax_registration: 'Tax registration',
    tax: 'Tax',
    f_tax: 'F-tax',
    vat: 'VAT',
    employer: 'Employer',
    group_information: 'Group information',
    parent_company: 'Parent company',
    group_parent_company: 'Group parent company',
    signing_authority: 'Signing authority',
    active: 'Active',
    reg_date: 'Registration date',
    n_a: 'n/a',
    show_all_contacts: 'Show all contacts',
    yes: 'Yes',
    no: 'No',
  },

  planning: {
    weekly_overview: 'Weekly overview',
    three_months: '3 months',
    one_month: '1 month',
    planned_elements_per_week: 'Planned elements per week',
    sum: 'Total',
    tracked_time: 'Tracked Time',
    event_week: 'Event Week',
    status: 'Status',
    week_one_letter: 'w',
    week: 'Week',
  },

  project: {
    title: 'Projects overview',
    show_only_active: 'Show only active',
    attributes: {
      name: 'Project',
      start_date: 'Start date',
      end_date: 'End date',
      financial_year: 'Financial year',
      deadline: 'Deadline',
      status: 'Status',
      status_changed_at: 'status changed date',
      time_reported: 'Time reported',
      hourly_avg_revenue: 'Hourly avg revenue',
      margin: 'Margin',
      margin_percent: 'Margin, %',
      notes: 'Notes',
      project_plan: 'Project plan',
      review: 'Review',
      project_notes: 'Project notes',
      none: 'None',
      actions: 'Actions',
    },
  },
  reported_time: {
    title: 'Reported time',
    attributes: {
      project: 'Project',
      project_name: 'Project name',
      task: 'Task',
      task_name: 'Task name',
      from_date: 'From date',
      to_date: 'To date',
      employee: 'Employee',
      service: 'Service',
      week: 'Week',
      estimated_time: 'Estimated time',
      reported_time: 'Reported time',
      billable_over_budget: 'Billable over budgeted time',
      not_billable_over_budget: 'Not billable over budgeted time',
      task_status: 'Task status',
      task_note: 'Task note',
      time_report_notes: 'Time report notes',
      billing_notes: 'Billing notes',
      time_report_comments: 'Time report comments',
      total_filtered: 'Total (filtered)',
      total_by_employee: 'Total by Employee',
      view_per_page: 'View per page',
    },
    empty: 'No search results available',
  },

  billing: {
    title: 'Billing overview',
    attributes: {
      scheduled: 'Scheduled and created invoices',
      total: 'Total',
      show_inactive_projects: 'Show inactive projects',
      show_my_responsible_only: 'Show my responsibility only',
      create_empty_invoice: 'Create empty invoice',
      create_empty_invoice_text:
        'This creates an empty invoice that is not linked to a billing plan or reported hours. You will be redirected to the empty invoice. Add items and assign to projects manually in the new window.',
      create_new_periodical_invoice: 'Create new periodical invoice',
    },
    invoice_overview: 'Invoices overview',
    project_overview: 'Projects overview',
    invoiced: 'Invoiced',
    postponed: 'Postponed',
    written_down: 'Written down',
    fees: {
      base_fee: 'Base fee',
      separate_fee: 'Separate fee',
      hourly_fee: 'Hourly fee',
    },
    forecast: 'Forecast',
    forecast_tooltip:
      'Forecasted fees are not scheduled fees that are projected, assuming the project will be renewed after its end date.',
    total_all_projects: 'Total for all project',
  },

  tabs: {
    dashboard: 'Overview',
    planning: 'Planning',
    invoicing: 'Invoicing',
    billing: 'Billing',
    project: 'Project',
    checkpoints: 'Checkpoints',
    reported_time: 'Reported time',
    purchases: 'Purchases',
    customer_folders: 'Files',
    templates: 'Templates',
    processes: 'Processes',
    settings: 'Settings',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Delete client',
      text: 'Are you sure you want to delete <strong>{name} ({org_number})</strong> client from the system?',
    },
    report_download: {
      title: 'Do you want to proceed?',
      text: 'Downloading credit report will create a notification for this individual. Are you sure?',
    },
  },

  processes: {
    general_process_information: 'General process information',
    general_it_information: 'General IT information',
    it_access_and_permissions: 'IT access and permissions',
    process_descriptions: 'Process descriptions',
    accounting_and_general_ledger: 'Accounting and general ledger',
    salary_fringe_benefits_and_tax: 'Salary, fringe benefits and tax',
    supplier_invoices: 'Supplier invoices (purchases)',
    receipts_and_travel_expense_reports: 'Receipts (purchases) & travel expense reports',
    customer_invoices_and_collection: 'Customer invoices and collection',
    project_reporting_and_work_in_progress: 'Project reporting & Work in progress',
    accruals_and_prepayments: 'Accruals & prepayments',
    bank_and_payments: 'Bank and payments',
    loans_and_other_credits: 'Loans and other Credits',
    cash_register_and_daily_sales: 'Cash register and daily sales',
    inventory: 'Inventory',
    real_estate: 'Real estate',
    fixed_assets: 'Fixed assets',
    activates_internally_developed_assets: 'Activates internally developed assets',
    securities_trading: 'Securities trading',
    suspense_account_and_missing_documents: 'Suspense account and missing documents',
    annual_closing_and_financial_statement: 'Annual closing and financial statement',
    income_tax_return: 'Income tax return',
    vat: 'VAT',
    management_reporting: 'Management reporting',
    archiving_and_backup: 'Archiving and backup',
    // attributes
    framework: 'Framework',
    system: 'System',
    we_do: 'We do',
    deadline: 'Deadline',
    reporting_deadline: 'Reporting deadline',
    reporting_frequency: 'Reporting frequency',
    periodic_summary: 'Periodic summary',
    description: 'Description',
    vat_reporting_frequency: {
      annually: 'Financial year',
      quarterly: 'Calendar quarter',
      monthly: 'Monthly',
    },
    accounting_and_general_ledger_framework: {
      k1: 'K1: förenklat årsbokslut',
      k2: 'K2',
      k3_less: 'K3 - Mindre företag',
      k3_bigger: 'K3 - Större företag',
      ifrs: 'IFRS',
      arkl: 'ÅRKL',
      other: 'Other',
    },
    vat_reporting_deadline: {
      '26_of_second_month_following_period': 'On the 26th of the second month following the accounting period',
      submitted_separately:
        'The VAT return is submitted separately in close connection with the income tax return** (not when trading with companies in other EU countries)',
      '12_of_second_month_following_period': 'The 12th of the second month following the accounting period',
      '12_of_second_month_after_period':
        'The 12th of the second month after the accounting period (the taxable amount is no more than SEK 40 million)',
      '26_of_first_month_after_period':
        'The 26th of the first month after the accounting period (must be used when the taxable amount exceeds SEK 40 million)',
    },
  },

  statuses: {
    prospect: 'Prospect',
    blacklist: 'Blacklist',
    active: 'Active',
    inactive: 'Inactive',
  },

  tags: {
    modal: {
      create: {
        title: 'Add new tag',
      },
    },
    attributes: {
      name: 'Tag name',
    },
  },

  it_systems: {
    modal: {
      create: {
        title: 'Add new system',
      },
    },
    attributes: {
      name: 'System name',
    },
  },
};
