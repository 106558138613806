<script setup lang="ts">
import { ref, onMounted, onUnmounted, nextTick, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { setInterval, clearInterval } from 'worker-timers';
import { CallbackFunction } from '@/types/Common';
import { clsx } from 'clsx';

const { t } = useI18n({ useScope: 'global' });

const loading = ref(false);
const delay = ref(3000);
const interval = ref<ReturnType<typeof setInterval>>();
const confirmButton = ref<HTMLButtonElement>();

const {
  title,
  message = null,
  delayTime = 3000,
  confirmBtnType = 'danger',
  size = 'm',
  confirmCallback,
} = defineProps<{
  title: string;
  confirmCallback?: CallbackFunction;
  confirmBtnType?: 'success' | 'danger';
  delayTime?: number;
  message?: string | null;
  size?: 's' | 'm' | 'l' | 'xl';
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'confirm'): void;
}>();

async function onConfirm() {
  try {
    loading.value = true;
    if (confirmCallback) {
      await confirmCallback();
    }
    emit('confirm');
  } catch (e) {
    //
  } finally {
    loading.value = false;
  }
}

const containerClasses = computed(() =>
  clsx({
    'is-small': size === 's',
    'is-medium': size === 'm',
    'is-large': size === 'l',
    'is-huge': size === 'xl',
  }),
);

onMounted(() => {
  delay.value = delayTime;
  if (delay.value > 0) {
    interval.value = setInterval(() => {
      delay.value -= 1000;
      if (delay.value === 0) {
        nextTick(() => {
          confirmButton.value?.focus();
        });
      }
    }, 1000);
  }
});

onUnmounted(() => {
  if (interval.value) {
    clearInterval(interval.value);
  }
});
</script>
<template>
  <teleport to="body">
    <div class="modal-overlay" role="dialog" style="z-index: 1000">
      <div class="modal-container" :class="containerClasses">
        <div class="modal-header">
          <h2 v-text="title" />
        </div>
        <div v-if="message" class="modal-content">
          <p v-html="message" />
        </div>
        <div class="modal-footer">
          <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
            <button class="button is-light" @click="emit('cancel')" :disabled="loading">
              {{ t('common.cancel') }}
            </button>
            <button
              ref="confirmButton"
              class="button mt-2 mt-sm-0"
              :class="{
                'is-loading': loading,
                'is-danger': confirmBtnType === 'danger',
                'is-success': confirmBtnType === 'success',
              }"
              @click.prevent="onConfirm"
              :disabled="delay > 0 || loading"
            >
              {{ t('common.confirm') }}
              <span v-if="delay > 0">({{ delay / 1000 }})</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
