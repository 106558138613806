<script setup lang="ts">
import { onMounted, reactive, ref, watch } from 'vue';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { createConfirmDialog } from 'vuejs-confirm-dialog';
import { useModal } from 'vue-final-modal';

import api from '@/services/api';

import {
  AppAlert,
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  AppPagination,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  CancelOrRolloverProjectModal,
  ConfirmDialog,
  ConfirmModal,
  EmptyValue,
  FontIcon,
  FormLabel,
  FormTextarea,
  PortalUserStatus,
  ProjectStatus,
} from '@/components';
import {
  ClientType,
  IClientContactFullResource,
  IClientCreditInfo,
  IClientUpdateInformationRequestBody,
} from '@/types/Client';
import useLoader from '@/composables/useLoader';
import useDate from '@/composables/useDate';
import useTime from '@/composables/useTime';
import { downloadFile } from '@/utils/file-downloader';
import { IProjectWithTeamsResource, ProjectStatusType } from '@/types/Project';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { storeToRefs } from 'pinia';
import useClientStore from '@/store/ClientStore';

type Props = { uuid: string };
const { uuid } = defineProps<Props>();

const { getCurrentDate } = useDate();
const { t, d, n, locale } = useI18n({ useScope: 'global' });
const { convertMinutesToTime } = useTime();

const router = useRouter();

const submitLoader = useLoader();

// Client data
const { client } = storeToRefs(useClientStore());

const form = reactive<IClientUpdateInformationRequestBody>({
  other_information: '',
});

// client credit data
const clientCreditDataLoader = useLoader({ useProgress: false });
const clientCreditData = ref<null | IClientCreditInfo>(null);

// Contacts data
const contactsElement = ref<HTMLDivElement | null>(null);
const contactsLoader = useLoader({ useProgress: false });
const currentContactsPage = useRouteQuery('overview-contact-page', '1', { transform: Number });
const contactsPerPage = ref(20);
const contactsTotal = ref(0);
const clientContacts = ref<IClientContactFullResource[]>([]);

// Projects & services data
const projects = ref<IProjectWithTeamsResource[]>([]);
const projectsLoader = useLoader({ useProgress: false });

async function getCreditClientInfo() {
  try {
    clientCreditDataLoader.start();
    const response = await api.clients.info.credit(uuid);

    clientCreditData.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    clientCreditDataLoader.finish();
  }
}

async function submit() {
  try {
    submitLoader.start();
    await api.clients.update(form, uuid);
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}

async function getContacts() {
  try {
    contactsLoader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentContactsPage.value.toString());
    searchParams.append('with_phone_or_email', '1');
    const response = await api.clients.contacts.list(uuid, searchParams);
    clientContacts.value = response.data;
    contactsPerPage.value = response.meta!.per_page;
    contactsTotal.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  } finally {
    contactsLoader.finish();
  }
}

async function getProjects() {
  try {
    projectsLoader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('statuses[]', 'active');
    searchParams.append('statuses[]', 'draft');
    const response = await api.projects.listWithFullResource(uuid, searchParams);
    projects.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    projectsLoader.finish();
  }
}

async function onDownloadCreditReport(event: PointerEvent) {
  if (client.value === null) return;

  const showConfirm =
    client.value.company_category.type === ClientType.INDIVIDUAL ||
    (client.value.company_category.type === ClientType.COMPANY &&
      client.value.company_category.name === 'Individual company');

  if (showConfirm) {
    const { open, close, destroy } = useModal({
      component: ConfirmModal,
      attrs: {
        title: t('client.confirm.report_download.title'),
        message: t('client.confirm.report_download.text'),
        async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
          try {
            setLoading(true);
            await downloadCreditReport();
            await close();
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        },
        onClosed() {
          destroy();
        },
      },
    });
    await open();
  } else {
    const target = event.target as HTMLElement;
    const button = (target.tagName.toLowerCase() === 'button' ? event.target : target.parentNode) as HTMLButtonElement;
    button.classList.add('is-loading');

    try {
      await downloadCreditReport();
    } catch (error) {
      console.error(error);
    } finally {
      button.classList.remove('is-loading');
    }
  }
}

async function downloadCreditReport() {
  const response = await api.clients.getClientCreditReportDownloadLink(uuid);
  downloadFile(response.data.url);
}

function compareDeadlineDateWithToday(date: null | string) {
  if (date) {
    const today = getCurrentDate();
    const deadline = new Date(date);
    const current = new Date(today);

    if (deadline <= current) {
      return true;
    }
  }

  return false;
}

function cancelProject(project: IProjectWithTeamsResource) {
  const dialog = createConfirmDialog(CancelOrRolloverProjectModal, {
    type: 'cancel',
    clientUuid: uuid,
    project: {
      id: project.id,
      name: project.name,
      has_unfinished_tasks: project.has_unfinished_tasks,
    },
    onConfirmed: async (newProjectId: number | null) => {
      await getProjects();
      if (newProjectId) {
        await router.push({ name: 'projects.edit', params: { uuid, id: newProjectId } });
      }
    },
  });

  dialog.reveal();
}

function rolloverProject(project: IProjectWithTeamsResource) {
  const dialog = createConfirmDialog(CancelOrRolloverProjectModal, {
    type: 'rollover',
    clientUuid: uuid,
    project: {
      id: project.id,
      name: project.name,
      has_unfinished_tasks: project.has_unfinished_tasks,
    },
    onConfirmed: async (newProjectId: number | null) => {
      await getProjects();
      if (newProjectId) {
        await router.push({ name: 'projects.edit', params: { uuid, id: newProjectId } });
      }
    },
  });

  dialog.reveal();
}

async function deleteProject(project: IProjectWithTeamsResource) {
  const dialog = createConfirmDialog(ConfirmDialog, {
    title: t('project.confirm.destroy.title'),
    message: t('project.confirm.destroy.text', { name: project.name }),
    confirmBtnType: 'danger',
    delayTime: 0,
    size: 'm',
    confirmCallback: async () => {
      await api.projects.destroy(uuid, project.id);
      await getProjects();
    },
  });

  dialog.reveal();
}

onMounted(async () => {
  form.other_information = client.value.other_information ?? '';
  await Promise.all([getContacts(), getProjects(), getCreditClientInfo()]);
});

watch(currentContactsPage, async () => {
  await getContacts();
  contactsElement.value!.scrollIntoView({ behavior: 'smooth', block: 'start' });
});
</script>

<template>
  <!-- Contacts -->
  <div ref="contactsElement">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <h2 class="mb-0" v-text="t('client.contact.title')" />
      <RouterLink custom :to="{ name: 'client.settings', params: { uuid } }" v-slot="{ href, navigate }">
        <a @click="navigate" :href="href" target="_blank">
          <AppButton color="secondary">
            <FontIcon name="users" />
            {{ t('client.overview.show_all_contacts') }}
          </AppButton>
        </a>
      </RouterLink>
    </div>
    <AppBox shadow v-if="contactsLoader.isLoading.value" class="text-center py-3">
      <AppLoader />
    </AppBox>
    <template v-else>
      <AppAlert v-if="clientContacts.length === 0">{{ t('client.contact.empty') }}</AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>{{ t('client.contact.name') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.contact.email') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.contact.phone') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.contact.roles') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.contact.portal_status') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr v-for="contact in clientContacts" :key="contact.uuid">
              <AppTableTd nowrap>
                <strong v-text="contact.name" />
              </AppTableTd>
              <AppTableTd nowrap>
                <span v-if="contact.email" v-text="contact.email" />
                <i v-else class="text-neutral-300" v-text="t('common.empty')" />
              </AppTableTd>
              <AppTableTd nowrap>
                <span v-if="contact.phone" v-text="contact.phone" />
                <i v-else class="text-neutral-300" v-text="t('common.empty')" />
              </AppTableTd>
              <AppTableTd nowrap>
                <span v-if="contact.roles.length" v-text="contact.roles.map((role) => role.name[locale]).join(', ')" />
                <i v-else class="text-neutral-300" v-text="t('common.empty')" />
              </AppTableTd>
              <AppTableTd nowrap>
                <PortalUserStatus :status="contact.portal_status" />
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination class="mt-3" :per-page="contactsPerPage" :total="contactsTotal" v-model="currentContactsPage" />
      </template>
    </template>
  </div>
  <!-- Projects -->
  <div class="mt-5">
    <h2 v-text="t('project.index.title')" />
    <AppBox shadow v-if="projectsLoader.isLoading.value" class="text-center py-3">
      <AppLoader />
    </AppBox>
    <template v-else>
      <AppAlert v-if="projects.length === 0">
        {{ t('project.empty') }}
      </AppAlert>
      <AppTable v-else hoverable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap>{{ t('project.attributes.name') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('project.attributes.status') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('project.attributes.start_end_date') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('client.project.attributes.deadline') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('project.step.price') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('project.step.team') }}</AppTableTh>
            <AppTableTh nowrap>
              <span
                v-tooltip.top="
                  `${t('client.attributes.reported')} | ${t('client.attributes.estimated')}
                    | ${t('client.attributes.planned')}`
                "
                nowrap
              >
                {{ t('client.attributes.r') }} | {{ t('client.attributes.e') }} | {{ t('client.attributes.p') }}
              </span>
            </AppTableTh>
            <AppTableTh nowrap>{{ t('client.attributes.marginal') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('project.attributes.tasks_in_portal') }}</AppTableTh>
            <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr v-for="project in projects" :key="project.id">
            <AppTableTd nowrap>
              <RouterLink
                custom
                :to="{ name: 'projects.edit', params: { uuid, id: project.id } }"
                v-slot="{ href, navigate }"
              >
                <a class="text-neutral-900 font-bold" @click="navigate" :href="href" target="_blank">
                  {{ project.name }}
                </a>
              </RouterLink>
            </AppTableTd>
            <AppTableTd nowrap>
              <ProjectStatus :status="project.status" />
            </AppTableTd>
            <AppTableTd nowrap>{{ d(project.start_date) }} / {{ d(project.end_date) }}</AppTableTd>
            <AppTableTd :class="compareDeadlineDateWithToday(project.deadline_date) ? 'deadline_date' : ''" nowrap>
              <span v-if="project.deadline_date" v-text="d(project.deadline_date)" />
              <span v-else v-text="t('client.project.attributes.none')" />
            </AppTableTd>
            <AppTableTd nowrap>
              <span v-if="project.annual_price">
                {{ t(`service.price_type.${project.price_type}`) }} - {{ n(project.annual_price, 'currency') }}
              </span>
              <i v-else class="text-neutral-300" v-text="t('common.empty')" />
            </AppTableTd>
            <AppTableTd nowrap class="d-flex">
              <div
                class="avatar initials is-small mr-1"
                v-tooltip="team.name ? team.name : t('common.no_name')"
                v-for="team in project.teams"
                :key="team.uuid"
                v-text="team.name ? team.name.charAt(0) : 'N'"
              />
            </AppTableTd>
            <AppTableTd nowrap>
              {{ convertMinutesToTime(project.total_reported_time) }}
              | {{ convertMinutesToTime(project.total_estimated_time) }} |
              {{ convertMinutesToTime(project.total_forecasted_time) }}
            </AppTableTd>
            <AppTableTd nowrap>{{ project.marginality }}</AppTableTd>
            <AppTableTd nowrap>{{ t('client.overview.n_a') }}</AppTableTd>
            <AppTableTd class="text-right" nowrap>
              <RouterLink
                custom
                :to="{ name: 'projects.view', params: { uuid, id: project.id } }"
                v-slot="{ href, navigate }"
              >
                <a @click="navigate" :href="href" target="_blank">
                  <AppButton
                    v-tooltip.left="t('project.tooltip.view', { name: project.name })"
                    size="small"
                    light
                    circle
                  >
                    <FontIcon name="eye" />
                  </AppButton>
                </a>
              </RouterLink>
              <RouterLink
                custom
                :to="{ name: 'projects.edit', params: { uuid, id: project.id } }"
                v-slot="{ href, navigate }"
              >
                <a @click="navigate" :href="href" target="_blank">
                  <AppButton
                    v-tooltip.left="t('project.tooltip.plan', { name: project.name })"
                    size="small"
                    light
                    circle
                    class="ml-2"
                  >
                    <FontIcon name="pencil" />
                  </AppButton>
                </a>
              </RouterLink>
              <AppButton
                v-if="project.status === ProjectStatusType.Active"
                v-tooltip.left="t('project.tooltip.cancel', { name: project.name })"
                @click.stop="cancelProject(project)"
                class="ml-2"
                color="danger"
                size="small"
                light
                circle
              >
                <FontIcon name="ban" />
              </AppButton>
              <AppButton
                v-if="project.status === ProjectStatusType.Active"
                v-tooltip.left="t('project.tooltip.rollover', { name: project.name })"
                @click.stop="rolloverProject(project)"
                class="ml-2"
                color="secondary"
                size="small"
                light
                circle
              >
                <FontIcon name="reload" />
              </AppButton>
              <AppButton
                v-tooltip.left="t('project.tooltip.destroy', { name: project.name })"
                @click.stop="deleteProject(project)"
                class="ml-2"
                size="small"
                color="danger"
                light
                circle
                v-if="project.status === ProjectStatusType.Draft"
              >
                <FontIcon name="trash" />
              </AppButton>
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
    </template>
  </div>
  <!-- Form -->
  <div class="mt-5">
    <AppBox shadow>
      <AppBoxBody>
        <form @submit.prevent="submit">
          <FormLabel html-for="other_information">{{ t('client.attributes.other_info') }}</FormLabel>
          <FormTextarea id="other_information" class="form-control" size="large" v-model="form.other_information" />
          <AppButton class="mt-3" color="success" :loading="submitLoader.isLoading.value">
            {{ t('common.save') }}
          </AppButton>
        </form>
      </AppBoxBody>
    </AppBox>
  </div>
  <!-- Invoice and credit info -->
  <div class="mt-5">
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h2 class="mb-0" v-text="t('client.overview.invoice_credit_info')" />
      <AppButton color="secondary" @click="onDownloadCreditReport">
        <FontIcon name="download" />
        {{ t('client.overview.download_credit_report') }}
      </AppButton>
    </div>
    <AppBox shadow v-if="clientCreditDataLoader.isLoading.value" class="text-center py-3">
      <AppLoader />
    </AppBox>
    <AppBox v-else shadow>
      <AppBoxBody>
        <div class="row">
          <div class="col-3">{{ t('client.overview.due') }}</div>
          <div class="col-9">{{ t('client.overview.n_a') }}</div>
        </div>
        <div class="row" v-if="clientCreditData && (clientCreditData.rating || clientCreditData.rating_text)">
          <div class="col-3">{{ t('client.overview.credit_rating') }}</div>
          <div class="col-9">
            {{ clientCreditData.rating }} |
            {{ clientCreditData.rating_text }}
          </div>
        </div>
        <div class="row" v-if="clientCreditData && clientCreditData.kf_debt">
          <div class="col-3">{{ t('client.overview.skulsaldo') }}</div>
          <div class="col-9">{{ clientCreditData.kf_debt }}</div>
        </div>
        <div class="row" v-if="clientCreditData && (clientCreditData.antal_anm || clientCreditData.sum_anm)">
          <div class="col-3">{{ t('client.overview.payment_remarks') }}</div>
          <div class="col-9">
            {{ clientCreditData.antal_anm }} |
            {{ clientCreditData.sum_anm }}
          </div>
        </div>
      </AppBoxBody>
    </AppBox>
  </div>
  <!-- Registered information -->
  <div class="mt-5" v-if="clientCreditData">
    <h2 v-text="t('client.overview.registered_information')" />
    <AppBox shadow v-if="clientCreditDataLoader.isLoading.value" class="text-center py-3">
      <AppLoader />
    </AppBox>
    <AppBox v-else shadow>
      <AppBoxBody>
        <div class="row">
          <div class="col-6" v-if="clientCreditData">
            <h4>{{ t('client.overview.general_information') }}</h4>
            <div class="row">
              <div class="col-4">{{ t('client.overview.status') }}</div>
              <div class="col-8">
                {{ clientCreditData.status ?? t('client.project.attributes.none') }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{ t('client.overview.incorporation_date') }}</div>
              <div class="col-8">
                {{
                  clientCreditData.registration_date
                    ? d(
                        clientCreditData.registration_date.substring(0, 4) +
                          '-' +
                          clientCreditData.registration_date.substring(4, 6) +
                          '-' +
                          clientCreditData.registration_date.substring(6, 8),
                      )
                    : t('client.project.attributes.none')
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{ t('client.overview.sni') }}</div>
              <div class="col-8">
                <span v-if="clientCreditData.industry || clientCreditData.industry_text">
                  {{ clientCreditData.industry }} -
                  {{ clientCreditData.industry_text }}
                </span>
                <span v-else>
                  {{ t('client.project.attributes.none') }}
                </span>
              </div>
            </div>
            <div class="row" v-if="clientCreditData">
              <div class="col-4">{{ t('client.overview.business_description') }}</div>
              <div class="col-8">
                {{ clientCreditData.activity_text ?? t('client.project.attributes.none') }}
              </div>
            </div>
            <div class="row" v-if="clientCreditData">
              <div class="col-4">{{ t('client.overview.share_capital') }}</div>
              <div class="col-8">
                {{ clientCreditData.share_capital ?? t('client.project.attributes.none') }}
              </div>
            </div>
            <!-- Tax registration-->
            <h4 class="mt-3">{{ t('client.overview.tax_registration') }}</h4>
            <AppTable hoverable v-if="clientCreditData">
              <AppTableHead>
                <AppTableTr>
                  <AppTableTh nowrap>{{ t('client.overview.tax') }}</AppTableTh>
                  <AppTableTh nowrap>{{ t('client.overview.active') }}</AppTableTh>
                  <AppTableTh nowrap>{{ t('client.overview.reg_date') }}</AppTableTh>
                </AppTableTr>
              </AppTableHead>
              <AppTableBody>
                <AppTableTr>
                  <AppTableTd nowrap>{{ t('client.overview.f_tax') }}</AppTableTd>
                  <AppTableTd nowrap>
                    <span v-if="clientCreditData.f_tax" v-text="t('client.overview.yes')" />
                    <span v-else v-text="t('client.overview.no')" />
                  </AppTableTd>
                  <AppTableTd nowrap>
                    <span v-if="clientCreditData.f_tax_start_date" v-text="d(clientCreditData.f_tax_start_date)" />
                    <EmptyValue v-else />
                  </AppTableTd>
                </AppTableTr>
                <AppTableTr>
                  <AppTableTd nowrap>{{ t('client.overview.vat') }}</AppTableTd>
                  <AppTableTd nowrap>
                    <span v-if="clientCreditData.vat" v-text="t('client.overview.yes')" />
                    <span v-else v-text="t('client.overview.no')" />
                  </AppTableTd>
                  <AppTableTd nowrap>
                    <span v-if="clientCreditData.vat_start_date" v-text="d(clientCreditData.vat_start_date)" />
                    <EmptyValue v-else />
                  </AppTableTd>
                </AppTableTr>
                <AppTableTr>
                  <AppTableTd nowrap>{{ t('client.overview.employer') }}</AppTableTd>
                  <AppTableTd nowrap>
                    <span v-if="clientCreditData.emp_tax" v-text="t('client.overview.yes')" />
                    <span v-else v-text="t('client.overview.no')" />
                  </AppTableTd>
                  <AppTableTd nowrap>{{ t('client.project.attributes.none') }}</AppTableTd>
                </AppTableTr>
              </AppTableBody>
            </AppTable>
          </div>
          <div class="col-6" v-if="clientCreditData">
            <h4>{{ t('client.overview.group_information') }}</h4>
            <div class="row">
              <div class="col-4">{{ t('client.overview.parent_company') }}</div>
              <div class="col-8">
                <span
                  v-if="clientCreditData.immediate_group_mother_name || clientCreditData.immediate_group_org_number"
                >
                  {{
                    clientCreditData.immediate_group_mother_name
                      ? clientCreditData.immediate_group_mother_name + ' | '
                      : ''
                  }}
                  {{ clientCreditData.immediate_group_org_number }}
                </span>
                <span v-else>
                  {{ t('client.project.attributes.none') }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-4">{{ t('client.overview.group_parent_company') }}</div>
              <div class="col-8">
                <span
                  v-if="
                    clientCreditData.group_mother_name ||
                    clientCreditData.group_mother_org_number ||
                    clientCreditData.group_mother_country_code
                  "
                >
                  {{ clientCreditData.group_mother_name ? clientCreditData.group_mother_name + ' | ' : '' }}
                  {{ clientCreditData.group_mother_org_number ? clientCreditData.group_mother_org_number + ' | ' : '' }}
                  {{ clientCreditData.group_mother_country_code }}
                </span>
                <span v-else>
                  {{ t('client.project.attributes.none') }}
                </span>
              </div>
            </div>
            <h4 class="mt-3">{{ t('client.overview.signing_authority') }}</h4>
            <div>
              <span>{{ clientCreditData.company_sign ?? t('client.project.attributes.none') }}</span>
            </div>
          </div>
        </div>
      </AppBoxBody>
    </AppBox>
  </div>
</template>

<style>
.deadline_date {
  color: red;
}
</style>
