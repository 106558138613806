<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';

import toast from '@/services/toast';
import useClientStore from '@/store/ClientStore';

const { locale } = useI18n({ useScope: 'global' });

type Props = { uuid: string; visibility: boolean };
const { uuid, visibility } = defineProps<Props>();

const clientStore = useClientStore();
const { filesSettings } = storeToRefs(clientStore);
</script>

<template>
  <div :class="{ hidden: !visibility }">
    <div class="box">
      <div class="box-body">
        <WeTemplates
          :api-base-url="filesSettings.api_base_url"
          :token="filesSettings.token"
          :company-uuid="uuid"
          :locale="locale"
          @notify-success="toast.success($event)"
          @notify-failure="toast.error($event)"
        />
      </div>
    </div>
    <div class="box mt-3">
      <div class="box-body">
        <WeFiler
          :api-base-url="filesSettings.api_base_url"
          :token="filesSettings.token"
          :company-uuid="uuid"
          :locale="locale"
          @notify-success="toast.success($event)"
          @notify-failure="toast.error($event)"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.hidden {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}
</style>
