export enum ProjectPriceType {
  FIXED = 'fixed',
  HOURLY = 'hourly',
}

export enum BillingFrequency {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  SEMI_ANNUALLY = 'semi-annually',
  ANNUALLY = 'annually',
}

export enum RepetitionFrequencyType {
  ANNUALLY = 'annually',
  SEMI_ANNUALLY = 'semi-annually',
  QUARTERLY = 'quarterly',
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
}

export enum ScheduledTimeUnitType {
  MINUTES = 'minutes',
  DAYS = 'days',
  PERCENT = 'percent',
}

export enum ProjectUserRole {
  RESPONSIBLE = 'responsible',
  TEAM_MEMBER = 'team_member',
}

export type SetLoading = (value: boolean) => void;

export type ConfirmDialogConfirmParams = {
  setLoading: SetLoading;
};

export type CallbackFunction = () => Promise<void>;

export interface SelectOption {
  label: string;
  value: string;
}

export enum SystemId {
  WE_VERIFY = 'we_verify',
  CRM = 'CRM',
  MY_AUDIT = 'my_audit',
  PLANNER = 'planner',
  STORE = 'store',
  WE_SIGN = 'we_sign',
}
