export default {
  resource: {
    name: 'Invoice | Invoices',
  },
  index: {
    title: 'Invoices',
    help: '',
    create_periodical_invoices: 'Create periodical invoices',
    create_separate_invoice: 'Create separate invoice',
  },

  drafts: {
    title: 'Invoice drafts',
    help: '',
    inbox_free: 'No more invoices. Well done!',
  },

  create_periodical_invoices: {
    title: 'Create periodical invoices',
  },
  create_separate_invoice: {
    title: 'Create separate invoice',
  },

  edit: {
    title: 'Edit invoice: (id#{id})',
    help: '',
  },

  form: {
    name: 'Name',
  },

  empty: 'No invoices',

  // Attributes
  attributes: {
    client: 'Client name',
    client_reference: 'Client reference',
    user: 'Our reference',
    amount: 'Amount',
    created_at: 'Creation date',
    updated_at: 'Updated at',
    internal_status: 'Internal status',
    external_status: 'External status',
    synced_at: 'Sync date',
    sync_status: 'Sync status',
    external_amount: 'External amount',
    difference: 'Difference',
    invoice_language: 'Invoice language',
    other_information: 'Other invoice information',
    billing_adress: 'Client billing adress',
    internal_note: 'Internal note',
    invoice_text: 'Invoice text',
  },

  details: {
    project: 'Project',
    estimated_margin_percent: 'Est, margin %',
    price_type: 'Price type',
    description: 'Description',
    article: 'Article',
    qty: 'Qty',
    unit: 'Unit',
    price_unit: 'Price/Unit',
    discount: 'Discount, %',
    amount: 'Amount',
    postpone_to_next_month: 'Postpone',
    invoicing: 'Invoicing',
    write_off: 'Write-off',
    total: 'Original',
    total_invoice_amount: 'Total invoice amount',
    fetch_not_invoiced_prices: 'Fetch all not invoiced prices',
    approve_and_sync: 'Approve & Sync',
    save_and_sync: 'Save & Sync',
  },

  filters: {
    clients: 'By client',
    users: 'By our reference',
    internal_statuses: 'By internal status',
    external_statuses: 'By external status',
    from_date: 'Created from date',
    to_date: 'Created to date',
    show_deleted: 'Show deleted',
    credited_or_deleted: 'Show credited/deleted',
    show_all: 'Show all',
  },

  // Tooltips
  tooltip: {
    edit: 'Edit invoice',
    destroy: 'Delete invoice',
    postpone: 'Postpone',
    write_off: 'Write-off',
    expand: 'Expand',
    minimize: 'Minimize',
    expand_all: 'Expand all',
    minimize_all: 'Minimize all',
    report_link: 'View reported time on project (opens in a new window)',
    billing_link: 'View billing overview (opens in new window)',
    project_link: 'Project overview page (opens in new window)',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Delete invoice',
      text: 'Are you sure you want to delete invoice?',
    },
  },

  unit: {
    pieces: 'Pieces',
    hours: 'Hours',
  },

  internal_status: {
    draft: 'Draft',
    approved: 'Approved',
    deleted: 'Deleted',
    unmatched: 'Unmatched',
    matched: 'Matched',
    postponed: 'Postponed',
    action_required: 'Action Required',
    empty: 'Empty',
  },

  external_status: {
    NotSent: 'Not sent',
    Unpaid: 'Unpaid',
    OverdueReminderSent: 'Overdue reminder sent',
    OverdueReminderNotSent: 'Overdue reminder not sent',
    Paid: 'Paid',
    Cancelled: 'Cancelled',
    Collection: 'Collection',
    Expires: 'Expired',
  },

  sync_status: {
    error: 'Error',
    success: 'Success',
  },

  price_type: {
    fixed: 'Fixed',
    hourly: 'Hourly',
    advance: 'Advance',
    extra_fee: 'Extra fee',
    discount: 'Discount',
    outlays: 'Outlays',
  },

  unsaved_modal: {
    title: 'Current account has unsaved changes. What do you want to do?',
    save_continue: 'Save changes and continue',
    discard_continue: 'Discard changes and continue',
  },

  distribution: {
    e_invoice: 'E-Invoice',
    email: 'Email',
    mail: 'Mail',
  },

  unmatched_error:
    'The amount on the sent invoice does not match to the total below. Invoice amount is {external_amount}',
  customer_settings_incomplete: {
    text: "The customer's invoice settings are incomplete.",
    link: 'Update them here.',
  },
};
