<script setup lang="ts">
/* eslint-disable */
import { ref, onMounted, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { createConfirmDialog } from 'vuejs-confirm-dialog';
import { Dropdown } from 'floating-vue';
import VueDatePicker from '@vuepic/vue-datepicker';

import api from '@/services/api';
import {
  AppAlert,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  AppButton,
  FontIcon,
  FormInput,
  FormLabel,
  AppPagination,
  AppLoader,
  ConfirmDialog,
  AppCollapse,
  AppDropdownSection,
  ColumnSettings,
  HelpInformation,
} from '@/components';
import useLoader from '@/composables/useLoader';

import useColumnSettings from '@/composables/useColumnSettings';
import VueSelect from 'vue-select';
import { useTitle } from '@vueuse/core';

const router = useRouter();
const { t, locale } = useI18n({ useScope: 'global' });
const loader = useLoader();

const todos = ref<number[]>([1]);

// Pagination
const page = useRouteQuery<number>('page', 1, { transform: Number });
const perPage = ref(20);
const total = ref(0);

// Columns
const { columns, isColumnEnable, enabledColumns } = useColumnSettings('todos_columns', [
  'type',
  'receiver',
  'deadline',
  'status',
  'created_by',
  'updated_at',
]);

// Filters
const filterLoader = useLoader();
const filterSearch = useRouteQuery<string>('search', '');
const filterFromDate = useRouteQuery<string>('from_date', '');
const filterToDate = useRouteQuery<string>('to_date', '');
const filterCreatedBy = useRouteQuery<string>('created_by', '');
const filterReceiver = useRouteQuery<string>('receiver', '');
const filterClient = useRouteQuery<string>('client', '');
const filterProject = useRouteQuery<string>('project', '');
const filterType = useRouteQuery<string>('type', '');
const filterStatus = useRouteQuery<string>('status', '');

async function resetFilters() {
  filterSearch.value = '';
  filterFromDate.value = '';
  filterToDate.value = '';
  filterCreatedBy.value = '';
  filterReceiver.value = '';
  filterClient.value = '';
  filterProject.value = '';
  filterType.value = '';
  filterStatus.value = '';
  await onFilter();
}

function onDeleteTodo(todo: unknown) {
  const { reveal, onConfirm } = createConfirmDialog(ConfirmDialog, {
    title: t('todo.confirm.destroy.title'),
    message: t('todo.confirm.destroy.text', {
      name: 'Name',
    }),
  });

  reveal();

  onConfirm(async () => {
    try {
      //await api.clients.destroy(client.uuid);
      await getTodos();
      if (todos.value.length === 0 && page.value > 1) {
        page.value -= 1;
      }
    } catch (error) {
      console.error(error);
    }
  });
}

async function getTodos() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', page.value.toString());
    searchParams.append('sort_by', 'name');
    searchParams.append('sort_dir', 'asc');
    // Set filters
    if (filterSearch.value) searchParams.append('search', filterSearch.value);
    if (filterFromDate.value) searchParams.append('from_date', filterFromDate.value);
    if (filterToDate.value) searchParams.append('to_date', filterToDate.value);
    if (filterCreatedBy.value) searchParams.append('created_by', filterCreatedBy.value);
    if (filterReceiver.value) searchParams.append('receiver', filterReceiver.value);
    if (filterClient.value) searchParams.append('client', filterClient.value);
    if (filterProject.value) searchParams.append('project', filterProject.value);
    if (filterType.value) searchParams.append('type', filterType.value);
    if (filterStatus.value) searchParams.append('status', filterStatus.value);

    // const response = await api.clients.list(searchParams);
    // todos.value = response.data;
    // perPage.value = response.meta!.per_page;
    // total.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  }
}

async function onFilter() {
  filterLoader.start();
  page.value = 1;
  await getTodos();
  filterLoader.finish();
}

onMounted(async () => {
  loader.start();
  await Promise.all([getTodos()]);
  loader.finish();
});

watch(page, async () => {
  loader.start();
  await getTodos();
  loader.finish();
});

const title = useTitle(computed(() => t('todo.index.title')));
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" translation="todo.index.help" />
      </div>
      <AppButton class="ml-auto" color="secondary">
        {{ t('todo.index.create') }}
      </AppButton>
      <Dropdown placement="bottom-end" :distance="10">
        <AppButton class="ml-2" light circle>
          <FontIcon name="table-options" />
        </AppButton>
        <template #popper>
          <ColumnSettings
            v-model="enabledColumns"
            :columns="columns"
            :get-label="(columnName) => t(`todo.attributes.${columnName}`)"
          />
        </template>
      </Dropdown>
    </div>
    <AppCollapse class="my-3" :title="t('common.filters')">
      <form @submit.prevent="onFilter" class="my-3">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 align-items-end">
          <!-- Filter search -->
          <div class="form-group col col-lg-6">
            <FormLabel html-for="filter_search">
              {{ t('todo.filters.search') }}
            </FormLabel>
            <FormInput type="search" v-model.trim="filterSearch" id="filter_search" icon="search" />
          </div>
          <!-- From date -->
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter_from_date">
              {{ t('todo.filters.from_date') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="filter_from_date"
                input-class-name="form-control"
                v-model="filterFromDate"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :locale="locale"
                :week-num-name="t('common.week_short')"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <!-- To date -->
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter_to_date">
              {{ t('todo.filters.to_date') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="filter_to_date"
                input-class-name="form-control"
                v-model="filterToDate"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :locale="locale"
                :week-num-name="t('common.week_short')"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <!-- Created by -->
          <div class="form-group col">
            <FormLabel html-for="filter_created_by">
              {{ t('todo.filters.created_by') }}
            </FormLabel>
            <VueSelect v-model="filterCreatedBy" :options="[]" label="name" input-id="filter_created_by" />
          </div>
          <!-- Receiver -->
          <div class="form-group col">
            <FormLabel html-for="filter_receiver">
              {{ t('todo.filters.receiver') }}
            </FormLabel>
            <VueSelect v-model="filterReceiver" :options="[]" label="name" input-id="filter_receiver" />
          </div>
          <!-- Client -->
          <div class="form-group col">
            <FormLabel html-for="filter_client">
              {{ t('todo.filters.client') }}
            </FormLabel>
            <VueSelect v-model="filterClient" :options="[]" label="name" input-id="filter_client" />
          </div>
          <!-- Project -->
          <div class="form-group col">
            <FormLabel html-for="filter_project">
              {{ t('todo.filters.project') }}
            </FormLabel>
            <VueSelect v-model="filterProject" :options="[]" label="name" input-id="filter_project" />
          </div>
          <!-- Type -->
          <div class="form-group col">
            <FormLabel html-for="filter_type">
              {{ t('todo.filters.type') }}
            </FormLabel>
            <VueSelect v-model="filterType" :options="[]" label="name" input-id="filter_type" />
          </div>
          <!-- Status -->
          <div class="form-group col">
            <FormLabel html-for="filter_status">
              {{ t('todo.filters.status') }}
            </FormLabel>
            <VueSelect v-model="filterStatus" :options="[]" label="name" input-id="filter_status" />
          </div>
        </div>
        <div>
          <AppButton :disabled="filterLoader.isLoading.value">
            {{ t('common.apply_filters') }}
          </AppButton>
          <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="filterLoader.isLoading.value">
            {{ t('common.reset_filters') }}
          </AppButton>
        </div>
      </form>
    </AppCollapse>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="todos.length === 0">
        {{ t('todo.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap v-if="isColumnEnable('type')">
                {{ t('todo.attributes.type') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('created_by')">
                {{ t('todo.attributes.created_by') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('updated_at')">
                {{ t('todo.attributes.updated_at') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('receiver')">
                {{ t('todo.attributes.receiver') }}
              </AppTableTh>
              <AppTableTh nowrap>
                {{ t('todo.attributes.text') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('deadline')">
                {{ t('todo.attributes.deadline') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('status')">
                {{ t('todo.attributes.status') }}
              </AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr v-for="todo in todos" :key="todo">
              <AppTableTd nowrap v-if="isColumnEnable('type')"></AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('created_by')"></AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('updated_at')"></AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('receiver')"></AppTableTd>
              <AppTableTd>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi architecto dolore dolorum eius eligendi
                impedit nesciunt quos sequi. A eligendi ipsum itaque libero optio quas reiciendis similique voluptatum?
                Adipisci, qui.
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('deadline')"></AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('status')"></AppTableTd>
              <AppTableTd nowrap class="text-right">
                <AppButton v-tooltip.left="t('todo.tooltip.complete')" class="ml-2" size="small" light circle>
                  <FontIcon name="check" />
                </AppButton>
                <AppButton v-tooltip.left="t('todo.tooltip.edit')" class="ml-2" size="small" light circle>
                  <FontIcon name="pencil" />
                </AppButton>
                <AppButton
                  v-tooltip.left="t('todo.tooltip.destroy')"
                  @click.stop="onDeleteTodo(todo)"
                  class="ml-2"
                  color="danger"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="trash" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination :per-page="perPage" :total="total" v-model="page" />
      </template>
    </template>
  </div>
</template>
