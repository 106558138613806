<script setup lang="ts">
import { AppBox, AppBoxBody, AppButton, FontIcon, PlanningProgressMultiBar } from '@/components';
import useTime from '@/composables/useTime';
import { IEventTimelineWeek } from '@/types/Event';
import { computed, ref } from 'vue';

const { expanded, data, weekNumber } = defineProps<{
  expanded: boolean;
  data: IEventTimelineWeek['tasks']['events'][0];
  userWorkingTimeMinutes: number;
  weekNumber: string;
}>();

const emit = defineEmits<{
  (e: 'toggle', uuid: string): void;
}>();

const dragging = ref(false);

const { convertMinutesToTime } = useTime();

const draggable = computed(() => data.projects.some((project) => project.tasks.some((task) => task.done_at === null)));

function dragstart(dragEvent: DragEvent) {
  if (!dragEvent.dataTransfer) return;
  dragging.value = true;
  dragEvent.stopPropagation();
  dragEvent.dataTransfer.dropEffect = 'move';
  dragEvent.dataTransfer.effectAllowed = 'move';
  dragEvent.dataTransfer.setData('source', 'client');
  dragEvent.dataTransfer.setData('from_week', weekNumber);
  const events = data.projects.reduce((ids, project) => {
    return [...ids, ...project.tasks.filter((task) => !task.done_at).map((task) => task.id)];
  }, [] as number[]);
  dragEvent.dataTransfer.setData('events', events.join(','));
}

function dragend() {
  dragging.value = false;
}
</script>

<template>
  <AppBox shadow :draggable="draggable" @dragstart="dragstart" @dragend="dragend" :class="{ draggable, dragging }">
    <AppBoxBody>
      <div class="row d-flex align-items-center">
        <div class="col-3">
          <RouterLink custom :to="{ name: 'client.overview', params: { uuid: data.uuid } }" v-slot="{ href, navigate }">
            <a
              draggable="false"
              class="word-break font-bold text-4 text-neutral-900"
              :href="href"
              target="_blank"
              @click="navigate"
            >
              {{ data.name }}
            </a>
          </RouterLink>
        </div>
        <div class="col-1 text-right">
          <AppButton size="small" @click.prevent="emit('toggle', data.uuid)" light circle>
            <FontIcon v-if="expanded" name="chevron-up" />
            <FontIcon v-else name="chevron-down" />
          </AppButton>
        </div>
        <div class="col-1 text-center">
          <strong class="text-5" v-text="convertMinutesToTime(data.time.budgeted)" />
        </div>
        <div class="col-1" />
        <div class="col-6">
          <PlanningProgressMultiBar
            :green="data.time.finished"
            :orange="data.time.reported"
            :gray="Math.max(data.time.remaining, 0)"
          />
        </div>
      </div>
    </AppBoxBody>
  </AppBox>
</template>
