<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { vOnClickOutside } from '@vueuse/components';
import { useMediaQuery } from '@vueuse/core';

import { LogoIcon, NavigationItem } from '@/components';
import useSidebarStore from '@/store/SidebarStore';
import { NavigationItemProps } from '@/types/Navigation';
import useAuthStore from '@/store/AuthStore';

const { t } = useI18n({ useScope: 'global' });
const sidebarStore = useSidebarStore();
const { expanded } = storeToRefs(sidebarStore);
const { toggle, close } = sidebarStore;
const authStore = useAuthStore();
const { authenticatedUser, isCustomerAdminRole, isSuperAdminRole } = storeToRefs(authStore);
const isLargeScreen = useMediaQuery('(min-width: 768px)');

const navigationItems = computed<NavigationItemProps[]>(() => [
  { text: t('navigation.home.index'), route: { name: 'overview' }, icon: 'dashboard' },
  { text: t('navigation.clients.index'), route: { name: 'clients.index' }, icon: 'users' },
  { text: t('navigation.contacts.index'), route: { name: 'contacts.index' }, icon: 'address-book' },
  { text: t('navigation.todos.index'), route: { name: 'todos.index' }, icon: 'checkbox' },
  { text: t('navigation.checkpoints.index'), route: { name: 'checkpoints.index' }, icon: 'flag' },
  { text: t('navigation.deadlines.index'), route: { name: 'deadlines.index' }, icon: 'clock-exclamation' },
  { text: t('navigation.projects.index'), route: { name: 'projects.index' }, icon: 'briefcase' },
  {
    text: t('navigation.internal_projects.index'),
    route: { name: 'internal-projects.index' },
    icon: 'calendar-event',
    guard() {
      return isCustomerAdminRole.value || isSuperAdminRole.value;
    },
  },
  {
    text: t('navigation.resource_planning.index'),
    icon: 'calendar-stats',
    children: [
      { text: t('navigation.resource_planning.management.index') },
      { text: t('navigation.resource_planning.client_visits.index'), route: { name: 'client-visits.index' } },
    ],
  },
  {
    text: t('navigation.invoices.index'),
    icon: 'file-invoice',
    children: [
      {
        text: t('navigation.invoices.draft.index'),
        route: { name: 'invoices.drafts' },
      },
      { text: t('navigation.invoices.all.index'), route: { name: 'invoices.index' } },
    ],
  },
  { text: t('navigation.my_coworkers'), icon: 'users-group', route: { name: 'coworkers.index' } },
  { text: t('navigation.service_organizations'), icon: 'building', route: { name: 'service-organizations.index' } },
  {
    text: t('navigation.time_sheets.index'),
    icon: 'report',
    children: [
      {
        text: t('navigation.time_sheets.my_time.index'),
        children: [
          {
            text: t('navigation.time_sheets.my_time.reported'),
            route: { name: 'time-sheets.time', params: { type: 'reported' } },
          },
          {
            text: t('navigation.time_sheets.my_time.parental'),
            route: { name: 'time-sheets.time', params: { type: 'parental' } },
          },
          {
            text: t('navigation.time_sheets.my_time.childcare'),
            route: { name: 'time-sheets.time', params: { type: 'childcare' } },
          },
        ],
      },
      {
        text: t('navigation.time_sheets.time_sheets.index'),
        route: { name: 'time-sheets.index' },
      },
      { text: t('navigation.time_sheets.time_periods.index'), route: { name: 'time-periods.index' } },
    ],
  },
  {
    text: t('navigation.statistics.index'),
    icon: 'chart-pie',
    children: [
      {
        text: t('navigation.statistics.revenue.index'),
        children: [
          { text: t('navigation.statistics.revenue.billing.index') },
          { text: t('navigation.statistics.revenue.billing_forecast.index') },
          { text: t('navigation.statistics.revenue.profitability.index') },
          { text: t('navigation.statistics.revenue.write_offs.index') },
          { text: t('navigation.statistics.revenue.wip_fixed_prices.index') },
          { text: t('navigation.statistics.revenue.wip_hourly_prices.index') },
        ],
      },
      {
        text: t('navigation.statistics.planning.index'),
        children: [
          { text: t('navigation.statistics.planning.burndown.index') },
          { text: t('navigation.statistics.planning.progress.index') },
          { text: t('navigation.statistics.planning.reported_budgeted_time.index') },
          { text: t('navigation.statistics.planning.education.index') },
          { text: t('navigation.statistics.planning.time_analysis.index') },
          {
            text: t('navigation.statistics.planning.my_activities.index'),
            route: { name: 'activities.my-schedule.index' },
          },
        ],
      },
    ],
  },
  {
    text: t('navigation.settings.index'),
    icon: 'settings',
    children: [
      {
        text: t('navigation.settings.services.index'),
        children: [
          { text: t('navigation.settings.services.services.index'), route: { name: 'services.index' } },
          { text: t('navigation.settings.services.service_mapping.index'), route: { name: 'service-mapping.index' } },
          { text: t('navigation.settings.services.prices.index'), route: { name: 'price-matrix.index' } },
        ],
      },
      {
        text: t('navigation.settings.internal_activities.index'),
        children: [
          {
            text: t('navigation.settings.internal_activities.templates.index'),
            route: { name: 'activities.templates.index' },
            guard() {
              return isCustomerAdminRole.value || isSuperAdminRole.value;
            },
          },
        ],
        guard() {
          return isCustomerAdminRole.value || isSuperAdminRole.value;
        },
      },
      {
        text: t('navigation.settings.service_organizations.index'),
        children: [
          {
            text: t('navigation.settings.service_organizations.types_and_roles.index'),
            route: { name: 'service-organizations.templates.index' },
          },
        ],
      },
      { text: t('navigation.settings.offices.index'), route: { name: 'offices.index' } },
      { text: t('navigation.settings.departments.index'), route: { name: 'departments.index' } },
      { text: t('navigation.settings.work_titles.index'), route: { name: 'work-titles.index' } },
      { text: t('navigation.settings.employees.index'), route: { name: 'users.index' } },
      {
        text: t('navigation.settings.salary.index'),
        route: { name: 'salary.settings.index' },
        guard() {
          return isCustomerAdminRole.value || isSuperAdminRole.value;
        },
      },
      {
        text: t('navigation.settings.working_schedule.index'),
        children: [
          { text: t('navigation.settings.working_schedule.holidays.index'), route: { name: 'free-days.index' } },
          {
            text: t('navigation.settings.working_schedule.working_day_duration.index'),
            route: { name: 'business-day.index' },
          },
        ],
      },
      {
        text: t('navigation.settings.invoicing.index'),
        children: [
          {
            text: t('navigation.settings.invoicing.accounting.index'),
            route: { name: 'accounting.index' },
          },
        ],
      },
    ],
  },
]);

function onClickOutside() {
  if (!isLargeScreen.value && expanded) {
    close();
  }
}
</script>

<template>
  <aside class="sidebar" :class="{ expanded }" v-on-click-outside="onClickOutside">
    <header class="sidebar-brand">
      <RouterLink class="sidebar-brand-link" :to="{ name: 'dashboard' }" v-tooltip="t('navigation.home.index')">
        <LogoIcon />
        <span v-if="expanded">WePlanner</span>
      </RouterLink>
    </header>
    <PerfectScrollbar tag="nav" class="sidebar-nav" :options="{ wheelPropagation: false }">
      <ul class="navigation-menu">
        <NavigationItem v-for="(item, index) in navigationItems" :key="index" v-bind="item" :guard="item.guard" />
      </ul>
    </PerfectScrollbar>
    <footer class="sidebar-footer">
      <div v-if="expanded && authenticatedUser" class="sidebar-customer" :title="authenticatedUser.customer.name">
        <img
          v-if="authenticatedUser.logo.light"
          :src="authenticatedUser.logo.light"
          :alt="authenticatedUser.customer.name"
        />
        <span v-text="authenticatedUser.customer.name" />
      </div>
      <button class="sidebar-toggle" @click.prevent="toggle">
        <i v-if="expanded" class="ti ti-chevrons-left"></i>
        <i v-else class="ti ti-chevrons-right"></i>
      </button>
    </footer>
  </aside>
</template>
