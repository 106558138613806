<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueDatePicker from '@vuepic/vue-datepicker';
import VueSelect from 'vue-select';
import { DateTime } from 'luxon';

import { AppButton, FormLabel, AppLoader } from '@/components';
import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import useClients from '@/composables/useClients';
import { IClientPreviewResource } from '@/types/Client';
import { ITimePeriodResource } from '@/types/TimePeriod';

const { t, locale } = useI18n({ useScope: 'global' });
const loader = useLoader({ useProgress: false });
const { clients, onSearchClients } = useClients();

const props = defineProps<{
  loading?: boolean;
  mode: 'periodical' | 'separate';
}>();

const latestDate = ref<string>('');
const earliestDate = ref<string>('');
const disabledDates = ref<string[]>([]);

const isPeriodical = computed(() => props.mode === 'periodical');
const isSeparate = computed(() => props.mode === 'separate');

const timePeriod = ref<ITimePeriodResource | null>(null);
const timePeriodDate = ref<null | string>(null);
const timePeriods = ref<ITimePeriodResource[]>([]);
const timePeriodsLoading = ref(false);
const clientUuid = ref<string>('');

const disabled = computed(() => {
  if (isSeparate.value) {
    return !clientUuid.value;
  }
  if (isPeriodical.value) {
    return !timePeriod.value;
  }
  return false;
});

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'periodicalCreate', timePeriodId: number): void;
  (e: 'separateCreate', clientUuid: string): void;
}>();

function submit() {
  if (isPeriodical.value) {
    if (!timePeriod.value) return;
    emit('periodicalCreate', timePeriod.value.id);
  }
  if (isSeparate.value) {
    emit('separateCreate', clientUuid.value);
  }
}

async function getTimePeriods() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.timePeriods.index({ searchParams });
    timePeriods.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

onMounted(async () => {
  timePeriodsLoading.value = true;
  loader.start();
  await Promise.all([getTimePeriods()]);
  loader.finish();
  timePeriodsLoading.value = false;

  // Set earliest date
  let earliestPeriod = timePeriods.value[0];
  for (let i = 1; i < timePeriods.value.length; i++) {
    const currentDate = DateTime.fromObject({ year: timePeriods.value[i].year, month: timePeriods.value[i].month });
    const earliest = DateTime.fromObject({ year: earliestPeriod.year, month: earliestPeriod.month });
    if (currentDate < earliest) {
      earliestPeriod = timePeriods.value[i];
    }
  }
  earliestDate.value = DateTime.fromObject({ day: 1, month: earliestPeriod.month, year: earliestPeriod.year })
    .endOf('month')
    .toFormat('yyyy-MM-dd');

  // Set latest date
  let latestPeriod = timePeriods.value[0];
  for (let i = 1; i < timePeriods.value.length; i++) {
    const currentDate = DateTime.fromObject({ year: timePeriods.value[i].year, month: timePeriods.value[i].month });
    const latest = DateTime.fromObject({ year: latestPeriod.year, month: latestPeriod.month });
    if (currentDate > latest) {
      latestPeriod = timePeriods.value[i];
    }
  }
  latestDate.value = DateTime.fromObject({ day: 1, month: latestPeriod.month, year: latestPeriod.year })
    .startOf('month')
    .toFormat('yyyy-MM-dd');

  // Set disabled dates
  const allDates = timePeriods.value.map((date) => DateTime.fromObject({ day: 1, year: date.year, month: date.month }));
  let currentDate = DateTime.fromISO(earliestDate.value);
  while (currentDate < DateTime.fromISO(latestDate.value)) {
    if (!allDates.some((date) => date.hasSame(currentDate, 'month'))) {
      disabledDates.value.push(
        currentDate.startOf('month').toFormat('yyyy-MM-dd'),
        currentDate.endOf('month').toFormat('yyyy-MM-dd'),
      );
    }
    currentDate = currentDate.plus({ months: 1 });
  }
});

onUnmounted(() => {
  timePeriod.value = null;
});

watch(timePeriodDate, (value) => {
  if (value === null) {
    timePeriod.value = null;
  } else {
    const month = parseInt(value.split('-')[0]);
    const year = parseInt(value.split('-')[1]);
    const period = timePeriods.value.find((item) => item.month === month && item.year === year);
    timePeriod.value = period ?? null;
  }
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container is-small"
    :click-to-close="false"
    :esc-to-close="false"
    content-style="overflow: visible;"
  >
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2 v-if="isPeriodical" v-text="t('invoice.create_periodical_invoices.title')" />
        <h2 v-else-if="isSeparate" v-text="t('invoice.create_separate_invoice.title')" />
      </div>
      <div class="modal-content">
        <div v-if="loader.isLoading.value" class="text-center">
          <AppLoader size="small" />
        </div>
        <template v-else>
          <div class="form-group" v-if="isSeparate">
            <FormLabel html-for="client_uuid" required>
              {{ t('project.attributes.client') }}
            </FormLabel>
            <VueSelect
              :filterable="false"
              @search="onSearchClients"
              v-model="clientUuid"
              :reduce="(option: IClientPreviewResource) => option.uuid"
              :options="clients"
              label="name"
              input-id="client_uuid"
              :placeholder="t('common.search')"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!clientUuid" v-bind="attributes as object" v-on="events" />
              </template>
              <template #no-options>{{ t('common.type_to_search') }}</template>
            </VueSelect>
          </div>
          <div class="form-group" v-if="isPeriodical">
            <FormLabel html-for="time_period" required>{{ t('time-sheet.attributes.time_period') }}</FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                input-class-name="form-control"
                :placeholder="t('common.select')"
                v-model="timePeriodDate"
                format="MM-yyyy"
                model-type="format"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                required
                auto-apply
                month-picker
                :min-date="earliestDate"
                :max-date="latestDate"
                :locale="locale"
                :disabled-dates="disabledDates"
                teleport
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <slot />
        </template>
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="emit('cancel')" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton class="mt-2 mt-sm-0" color="secondary" :loading="loading" :disabled="disabled">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
